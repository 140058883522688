import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc, startAfter, startAt,
  updateDoc
} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getStorage, uploadString, ref, StringFormat, getDownloadURL} from 'firebase/storage';
import html2canvas from 'html2canvas';
import produce from 'immer';
import {first, uniq, set, last, cloneDeep, pick, merge} from 'lodash';
import nanoid from 'nanoid';
import {Field} from '../model/fields';
import {Report, Template} from '../model/objects';
import {ICI} from '../model/propertyTypes';
import {TYPE_SUMMARY_DETAIL} from '../model/reportTypes';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_IMAGE_HEIGHT,
  defaultColumnsForType,
  defaultColumnStyle,
  newConfig
} from './config';
import {SECTION_DIVIDER, SECTION_FIELD_LIST, SECTION_MAP, SECTION_PHOTO} from './sectionTypes';
import {ALIGN_LEFT, TRANSFORM_NONE} from '../model/fonts';
import {ALMOST_BLACK} from '../model/colors';


const mapTemplate = template => {
  return {...produce(template, td => {
    td.summary.columns = td.summary.columns.map(c => produce(c, cd => {
      cd.fields = cd.fields.map(Template.mapField);
    }));

    td.detail.pages = td.detail.pages.map(p => produce(p, pd => {
      pd.sections = pd.sections.map(s => produce(s, sd => {
        if ("field" in sd) {
          sd.field = Template.mapField(sd.field);
        }
        if ("fields" in sd) {
          sd.fields = sd.fields.map(Template.mapField);
        }
      }));
    }));

    td.single.columns = td.single.columns.map(c => produce(c, cd => {
      cd.fields = cd.fields.map(Template.mapField);
    }));
  })};
};

const fillTemplate = template => {
  ["single", "summary"].forEach(config => {
    if (template[config]) {
      const t = template[config];
      if (!t.style.index) {
        t.style.index = defaultColumnStyle().index;
        t.style.index.font.size = t.style.body.font.size;
        t.style.index.font.unit = t.style.body.font.unit;
      }
      ["header", "body", "index"].forEach(section => {
        const s = t.style[section];
        if (s) {
          if (!s.font.align) {
            s.font.align = ALIGN_LEFT;
          }
          if (!s.font.family) {
            console.log(DEFAULT_FONT_FAMILY);
            s.font.family = DEFAULT_FONT_FAMILY;
          }
        }
      });
    }
  })
  return template;
}

const markDirty = (state) => {
  if (state.templates.curr) {
    state.templates.curr.dirty = true;
  }
  if (state.generate.result) {
    state.generate.result.dirty = true;
  }
}

export const chooseTemplate = createAsyncThunk(
  'report/chooseTemplate',
  (template, {dispatch, rejectWithValue}) => {
    try {
      dispatch(applyTemplate(Template.map(template ? template : newConfig())));
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const addTemplate = createAsyncThunk(
  'report/addTemplate',
  async (template, {getState, rejectWithValue}) => {
    const {auth, cart} = getState();
    const now = new Date();

    try {
      const t = mapTemplate(template);
      t.types = uniq(cart.comps.map(it => it.type));
      t.created = now;
      t.created_by = {
        name: auth.user.displayName,
        email: auth.user.email
      };
      t.updated = now;

      await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'templates'), t);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const copyTemplate = createAsyncThunk(
    'report/copyTemplate',
    async ({template, title}, {getState, rejectWithValue}) => {
      const {auth} = getState();
      const now = new Date();

      try {
        const t = {...template}
        t.title = title;
        t.created = now;
        t.created_by = {
          name: auth.user.displayName,
          email: auth.user.email
        };
        t.updated = now;
        t.copy_of = template.id;

        const ref = await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'templates'), t);
        await updateDoc(ref, {id: ref.id});
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
);

export const removeTemplate = createAsyncThunk(
  'report/removeTemplate',
  async (templateId, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      await deleteDoc(doc(getFirestore(), 'orgs', org, 'templates', templateId));
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const saveTemplate = createAsyncThunk(
  'report/saveTemplate',
  async (template, {getState, rejectWithValue}) => {
    const {auth} = getState();
    const org = auth.org.id;
    try {
      const t = mapTemplate(template);
      t.updated = new Date();
      t.updated_by = {
        name: auth.user.displayName,
        email: auth.user.email
      }

      await setDoc(doc(getFirestore(), 'orgs', org, 'templates', t.id), t, {merge: true});
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const touchTemplate = createAsyncThunk(
  'report/touchTemplate',
  async (template, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      await updateDoc(doc(getFirestore(), 'orgs', org, 'templates', template.id), {updated: new Date()});
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadTemplates = createAsyncThunk(
  'report/loadTemplates',
  async (arg, {getState, rejectWithValue}) => {
      const org = getState().auth.org.id;
      try {
        const result = await getDocs(query(
          collection(getFirestore(), 'orgs', org, 'templates'),
          orderBy('updated', 'desc')
        ));
        return result.docs.map(it => fillTemplate(Template.create(it)));
      }
      catch(err) {
        console.error(err);
        return rejectWithValue(err);
      }
  }
);

export const generateImageReport = createAsyncThunk(
  'report/generateImage',
  async ({root, items, map, config, comps}, {dispatch, getState, rejectWithValue}) => {
    const {auth, report} = getState();
    try {
      const now = new Date();
      const reportPath = report.generate.result ? report.generate.result.folder : `${auth.org.id}/reports/${nanoid()}`;

      const put = async (b64, fn, width, height) => {
        const upload = await uploadString(ref(getStorage(), `${reportPath}/${fn}`), b64, StringFormat.DATA_URL);
        const downloadURL = await getDownloadURL(upload.ref);
        dispatch(incGenerateProgress());

        return {
          name: fn,
          url: downloadURL,
          bytes: upload.metadata.size,
          format: upload.metadata.contentType,
          width, height
        };
      };

      const render = async (el,i) => {
        const dpr = 2; //window.devicePixelRatio;

        const c = await html2canvas(el, {useCORS: true, scale: dpr});
        const b64 = c.toDataURL("image/jpeg");

        let fn = i === 0 ? "Summary.jpeg" : (el.getAttribute("data-comp-title") || `comp-${i}`) + ".jpeg";
        return await put(b64, fn, el.scrollWidth * dpr, el.scrollHeight * dpr);
      }

      const rootEl = document.querySelector(root);
      const els = [];
      items.forEach(s => rootEl.querySelectorAll(s).forEach(it => els.push(it)));

      dispatch(initGenerateProgress(els.length + (map?1:0)));

      const files = [];
      for (let i = 0; i < els.length; i++) {
        const el = els[i];
        files.push(await render(el, i));
      }

      if (map) {
        let data;
        if (map.layers === 1) {
          data = map.layer.canvas.toDataURL('image/jpeg');
        }
        else {
          const canvas = document.createElement('canvas');
          canvas.width = map.width;
          canvas.height = map.height;
          const g = canvas.getContext('2d');
          map.layers.forEach(l => {
            g.drawImage(l, 0, 0);
          });

          data = canvas.toDataURL('image/jpeg');
        }

        files.push(await put(data, 'Map.jpeg', map.width, map.height));
      }

      let r = {
        type: config.type,
        title: config.title,
        folder: reportPath,
        created: now,
        created_by: {
          name: auth.user.displayName,
          email: auth.user.email
        },
        comps: comps.map(c => c.id),
        files,
        config: mapTemplate(config),
        dirty: false
      };

      let reports = collection(getFirestore(), 'orgs', auth.org.id, 'reports');
      let result;
      if (report.generate.result && report.generate.result.id) {
        await setDoc(doc(reports, report.generate.result.id), r)
        result = await getDoc(doc(reports, report.generate.result.id));
      }
      else {
        result = await getDoc(
          await addDoc(reports, r)
        );
      }
      return Report.create(result);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
)

export const exportReport = createAsyncThunk(
  'report/export',
  async ({report, format}, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const result = await httpsCallable(getFunctions(), 'exportReport')({
        report: report.id, org, format
      });
      const r = result.data;
      if (r.status === 'success') {
        let p = Promise.reject();
        for (let i = 0; i < 10; i++) {
          p = p.catch(() => new Promise((resolve, reject) => {
            setTimeout(() => {
              getDownloadURL(ref(getStorage(), r.path))
                .then(resolve)
                .catch(reject);
            }, 5000)
          }))
        }

        return await p;
      }
      else return rejectWithValue(r);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteReport = createAsyncThunk(
  'report/delete',
  async (report, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const result = await httpsCallable(getFunctions(), 'deleteReport')({folder: report.folder});
      if (result.data.status === "success") {
        await deleteDoc(doc(getFirestore(), 'orgs', org, 'reports', report.id));
        return report.id;
      }
      else {
        return rejectWithValue(result.data);
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const pageReports = createAsyncThunk(
  'report/page',
  async (forward, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    const list = getState().report.list;
    try {
      const newPage = list.page+(forward ? 1 : -1);

      let q = query(
        collection(getFirestore(), 'orgs', org, 'reports'),
        orderBy(list.sort.field, list.sort.order),
        limit(list.size)
      );

      if (list.page > -1) {
        if (forward) {
          q = query(q, startAfter(list.last));
        }
        else {
          q = query(q, startAt(last(list.stack)));
        }
      }

      return {
        page: newPage, docs: (await getDocs(q)).docs
      };
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
)

const report = createSlice({
  name: 'report',
  initialState: {
    curr: {
      type: TYPE_SUMMARY_DETAIL,
      title: "",
      hasSubject: false,
      subject: null,
      ...newConfig()
    },
    view: {
      summary: {
        tab: 0
      },
      detail: {
        tab: 0,
        page: 0
      },
      preview: {
        step: 0
      },
      single: {
        tab: 0
      },
      map: {
        tab: 0
      }

    },
    templates: {
      curr: {
        id: "",
        title: "",
        dirty: false,
        new: false,
      },
      all: []
    },
    generate: {
      result: null,
      progress: {total: 0, done: 0}
    },
    export: {
      result: null
    },
    list: {
      page: -1,
      size: 10,
      values: [],
      last: null,
      stack: [],
      sort: {
        field: "created",
        order: "desc"
      },
    }
  },
  reducers: {
    clearReport: (state, action) => {
      state.result = null;
      state.generate.progress.total = 0;
      state.generate.progress.done = 0;
      state.generate.result = null;
      state.export.result = null;
      state.templates.curr.id = "";
      state.templates.curr.title = "";
      state.templates.curr.dirty = false
      state.templates.curr.new = false;
    },
    openReport: (state, action) => {
      const report = action.payload;
      state.curr.title = report.title;
    },
    setHasSubject: (state, action) => {
      state.curr.hasSubject = action.payload;
    },

    setSubject: (state, action) => {
      state.curr.subject = action.payload;
    },

    setNewTemplate: (state, action) => {
      const isNew = action.payload;
      state.templates.curr.new = isNew;
      state.curr = {
        ...state.curr,
        ...newConfig()
      }
      if (isNew) {
        state.templates.curr.id = '';
        state.templates.curr.title = '';
      }
    },
    setTemplateTitle: (state, action) => {
      state.templates.curr.title = action.payload;
    },
    applyTemplate: (state, action) => {
      const t = cloneDeep(action.payload);
      const c = pick(state.curr, ["type", "title", "hasSubject", "subject"]);
      state.curr = {
        ...newConfig(),
        ...t,
        ...c
      };

      state.templates.curr.id = t.id || '';
      state.templates.curr.title = t.title || '';
      state.templates.curr.types = t.types || '';
      state.templates.curr.dirty = false;
      state.templates.curr.created = t.created || '';
      state.templates.curr.updated = t.updated || '';

      const firstType = first(state.templates.curr.types||[]) || ICI;
      for (const k of ["summary", "single"]) {
        const config = state.curr[k];
        if (!config) continue;

        if ((config.columns||[]).length === 0) {
          state.curr[k].columns = defaultColumnsForType(firstType, k === "single"? [{
            id: nanoid(),
            name: "Column 5",
            fields: [Field.BRIEF]
          }] : []);
        }

        if (!config.style.caption) {
          state.curr[k].style.caption = defaultColumnStyle().caption;
        }
      }

      // migrate from pre page detail template
      if (state.curr.detail.sections && !state.curr.detail.pages) {
        state.curr.detail.pages = [{
          sections: state.curr.detail.sections
        }]
        delete state.curr.detail.sections;
      }

      // set heights for map and photo sections
      (state.curr.detail.pages||[]).forEach(p => {
        p.sections.forEach(s => {
          if ([SECTION_PHOTO, SECTION_MAP].includes(s.type) && typeof s.height === 'undefined') {
            s.height = DEFAULT_IMAGE_HEIGHT;
          }
        })
      })

      if (!state.curr.detail.options) {
        state.curr.detail.options = {...newConfig().detail.options}
      }

      if (state.curr.detail.sections) {
        delete state.curr.detail.sections;
      }

      // default font family migration
      if (!state.curr.style.defaultFont) {
        state.curr.style.defaultFont = {
          defaultFont: {
            family: DEFAULT_FONT_FAMILY,
          }
        }
      }
    },

    updateStyle: (state, action) => {
      const {section, key, value} = action.payload;
      markDirty(state);
      state.curr.style[section][key] = value;
    },

    updateConfig: (state, action) => {
      const {path, value, dirty = true} = action.payload;
      if (dirty === true) {
        markDirty(state);
      }
      set(state.curr, path, value);
    },

    updateView: (state, action) => {
      const {path, value} = action.payload;
      set(state.view, path, value);
    },

    updateAllFonts: (state, action) => {
      const {key, value} = action.payload;
      set(state.curr, "header.style.title.font."+key, value);
      set(state.curr, "header.style.date.font."+key, value);
      set(state.curr, "header.style.org.font."+key, value);
      set(state.curr, "summary.style.caption.font."+key, value);
      set(state.curr, "summary.style.header.font."+key, value);
      set(state.curr, "summary.style.body.font."+key, value);
      set(state.curr, "summary.style.index.font."+key, value);
      set(state.curr, "single.style.caption.font."+key, value);
      set(state.curr, "single.style.header.font."+key, value);
      set(state.curr, "single.style.body.font."+key, value);
      set(state.curr, "single.style.index.font."+key, value);
      set(state.curr, "detail.style.banner.font."+key, value);
      set(state.curr, "detail.style.section.heading.font."+key, value);
      set(state.curr, "detail.style.section.body.font."+key, value);
      set(state.curr, "map.popup.index.style.font."+key, value);
      set(state.curr, "map.popup.distance.style.font."+key, value);
      state.curr.map.popup.fields.forEach((f,i) => {
        set(state.curr, `map.popup.fields[${i}].style.font.${key}`, value);
      });
      markDirty(state);
    },

    addColumn: (state, action) => {
      const config = action.payload;
      markDirty(state);
      state.curr[config].columns.push({
        id: nanoid(), name: `Column ${state.curr[config].columns.length+1}`, fields: []
      });
    },
    removeColumn: (state, action) => {
      const {column,config} = action.payload;
      markDirty(state);
      state.curr[config].columns.splice(column, 1);
    },
    moveColumn: (state, action) => {
      const {from, to, config} = action.payload;
      const c = state.curr[config].columns[from];
      markDirty(state);
      state.curr[config].columns.splice(from, 1);
      state.curr[config].columns.splice(to, 0, c);
    },
    setColumnName: (state, action) => {
      const {column, name, config} = action.payload;
      markDirty(state);
      state.curr[config].columns[column].name = name;
    },
    addFieldsToColumn: (state, action) => {
      const {column, fields, config} = action.payload;
      const fieldsToAdd =
        fields.filter(f => !state.curr[config].columns[column].fields.map(it => it.path).includes(f.path));

      markDirty(state);
      state.curr[config].columns[column].fields.push(...fieldsToAdd);
    },
    removeFieldFromColumn: (state, action) => {
      const {field, column, config} = action.payload;
      markDirty(state);
      state.curr[config].columns[column].fields.splice(field, 1);
    },
    moveFieldInColumn: (state, action) => {
      const {from, to, column, config} = action.payload;
      const f = state.curr[config].columns[column].fields[from];
      markDirty(state);
      state.curr[config].columns[column].fields.splice(from, 1);
      state.curr[config].columns[column].fields.splice(to, 0, f);
    },
    setFieldStyleInColumn: (state, action) => {
      const {field, column, style, value, config} = action.payload;
      const styles = state.curr[config].columns[column].styles || {};

      if (style == null) {
        // clear it
        delete styles[field.path];
      }
      else {
        styles[field.path] = set({
          ...styles[field.path],
        }, style, value);
      }
      markDirty(state);
      state.curr[config].columns[column].styles = styles;
    },
    setFieldLabelInColumn: (state, action) => {
      const {field, column, value, config} = action.payload;
      const labels = state.curr[config].columns[column].labels || {};

      markDirty(state);
      labels[field.path] = value;
      state.curr[config].columns[column].labels = labels;
    },

    addPage: (state, action) => {
      state.curr.detail.pages.push({
        sections: []
      });
      state.view.detail.page = state.curr.detail.pages.length-1;
      markDirty(state);
    },

    removePage: (state, action) => {
      const p = state.view.detail.page || 0;
      if (state.curr.detail.pages.length > 1) state.curr.detail.pages.splice(p, 1);
      state.view.detail.page = Math.max(0, p-1);
      markDirty(state);
    },

    addSection: (state, action) => {
      const {page, type = SECTION_FIELD_LIST} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections.push({
        id: nanoid(),
        type,
        fields: [],
        span: type === SECTION_DIVIDER ? 2 : 1,
        border: false,
        height: DEFAULT_IMAGE_HEIGHT
      });
    },
    removeSection: (state, action) => {
      const {section, page} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections.splice(section, 1);
    },
    moveSection: (state, action) => {
      const {from, to, page} = action.payload;
      const s = state.curr.detail.pages[page].sections[from];
      markDirty(state);
      state.curr.detail.pages[page].sections.splice(from, 1);
      state.curr.detail.pages[page].sections.splice(to, 0, s);
    },

    moveSectionToPage: (state, action) => {
      const {section, from, to} = action.payload;
      const s = state.curr.detail.pages[from].sections[section];
      state.curr.detail.pages[from].sections.splice(section, 1);
      state.curr.detail.pages[to].sections.push(s)
      markDirty(state);
    },

    setSectionType: (state, action) => {
      const {section, type, page} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].type = type;
    },

    setSectionTitle: (state, action) => {
      const {section, title, page} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].title = title != null ? title : null;
    },

    toggleSectionSpan: (state, action) => {
      const {section, page} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].span = state.curr.detail.pages[page].sections[section].span === 1 ? 2 : 1;
    },

    toggleSectionBorder: (state, action) => {
      const {section, page} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].border = !state.curr.detail.pages[page].sections[section].border;
    },

    addFieldToSection: (state, action) => {
      const {section, page, fields} = action.payload;
      const fieldsToAdd =
        fields.filter(f => !state.curr.detail.pages[page].sections[section].fields.map(f => f.path).includes(f.path));
      markDirty(state);
      state.curr.detail.pages[page].sections[section].fields.push(...fieldsToAdd);
    },

    removeFieldFromSection: (state, action) => {
      const {field, section, page} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].fields.splice(field, 1);
    },

    moveFieldInSection: (state, action) => {
      const {from, to, place, section, page} = action.payload;
      const s = state.curr.detail.pages[page].sections[section];

      // split the fields into their "columns"
      const cols = [[],[]];
      let c = 0;  // current column index
      let xf, yf; // from coordinates
      let xt, yt; // to coordinates
      s.fields.forEach((f,i) => {
        const span = (s.spans||{})[f.path] || 2;
        if (c + span > cols.length) {
          c = 0;
        }
        cols[c].push(f);

        if (i === from) {
          xf = c;
          yf = cols[c].length - 1;
        }
        if (i === to) {
          xt = c;
          yt = cols[c].length - 1;
        }

        c += span;
      });

      // move the field in the column split
      cols[xf].splice(yf, 1);
      cols[xt].splice(yt + (place==='after'?1:0), 0, s.fields[from]);

      // recombine the columns
      c = 0
      const fields = [];
      while (cols[0].length > 0 || cols[1].length > 0) {
        c = c % cols.length;

        const f = cols[c].shift();
        if (!f) {
          c += 1;
          continue;
        }

        fields.push(f);
        c += ((s.spans||{})[f.path] || 2);
      }

      s.fields = fields;
    },

    setFieldInSection: (state, action) => {
      const {field, section, page} = action.payload;

      markDirty(state);
      state.curr.detail.pages[page].sections[section].fields = [field];
    },

    setFieldStyleInSection: (state, action) => {
      const {field, section, page, style, value} = action.payload;

      const styles = state.curr.detail.pages[page].sections[section].styles || {};
      styles[field.path] = set({
        ...styles[field.path],
      }, style, value);
      markDirty(state);
      state.curr.detail.pages[page].sections[section].styles = styles;
    },

    setFieldLabelInSection: (state, action) => {
      const {field, section, page, value} = action.payload;

      const labels = state.curr.detail.pages[page].sections[section].labels || {};
      labels[field.path] = value;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].labels = labels;
    },

    setFieldSpanInSection: (state, action) => {
      const {field, section, page, value} = action.payload;

      const spans = state.curr.detail.pages[page].sections[section].spans || {};
      spans[field.path] = value;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].spans = spans;
    },

    setSectionTags: (state, action) => {
      const {section, page, tags} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].tags = tags;
    },

    setSectionCategory: (state, action) => {
      const {section, page, category} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].category = category;
    },

    setSectionOrientation: (state, action) => {
      const {section, page, orientation} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].orientation = orientation;
    },

    setSectionHeight: (state, action) => {
      const {section, page, height} = action.payload;
      markDirty(state);
      state.curr.detail.pages[page].sections[section].height = height;
    },

    setSectionAspect: (state, action) => {
      const {id, aspect} = action.payload;
      state.curr.detail.pages.forEach(p => {
        const s = p.sections.find(it => it.id === id);
        if (s) {
          s.aspect = aspect;
        }
      })
    },

    addFieldsToMapPopup: (state, action) => {
      const {fields} = action.payload;
      fields.forEach(field => {
        state.curr.map.popup.fields.push({
          path: field.path,
          style: {
            font: {
              family: DEFAULT_FONT_FAMILY,
              style: "normal",
              weight: 500,
              size: 8,
              unit: 'pt',
              color: ALMOST_BLACK,
              transform: TRANSFORM_NONE
            }
          }
        })
      });
      markDirty(state);
    },

    moveFieldInMapPopup: (state, action) => {
      const {from, to} = action.payload;
      const f = state.curr.map.popup.fields[from];
      state.curr.map.popup.fields.splice(from, 1);
      state.curr.map.popup.fields.splice(to, 0, f);
      markDirty(state);
    },

    removeFieldFromMapPopup: (state, action) => {
      const {index} = action.payload;
      state.curr.map.popup.fields.splice(index, 1);
      markDirty(state);
    },

    sortReports: (state, action) => {
      const {field, order} = action.payload;
      state.list.page = -1;
      state.list.sort = {
        field, order
      }
    },

    initGenerateProgress: (state, action) => {
      state.generate.progress.total = action.payload;
      state.generate.progress.done = 0;
    },

    incGenerateProgress: (state, action) => {
      state.generate.progress.done += 1;
    },

    patchTemplate: (state, action) => {
      const {id, patch} = action.payload;
      const t = (state.templates.all||[]).findIndex(t => t.id === id);
      if (t > -1) {
        state.templates.all[t] = merge(state.templates.all[t], patch);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTemplates.fulfilled, (state, action) => {
        state.templates.all = action.payload;
      })
      .addCase(saveTemplate.fulfilled, (state, action) => {
        state.templates.all = [];
      })
      .addCase(addTemplate.fulfilled, (state, action) => {
        state.templates.all = [];
      })
      .addCase(generateImageReport.pending, (state, action) => {
        // state.generate.result = null;
      })
      .addCase(generateImageReport.fulfilled, (state, action) => {
        state.generate.result = {...action.payload, dirty: false}
        state.list.page = -1;
      })
      .addCase(exportReport.fulfilled, (state, action) => {
        state.export.result = action.payload;
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        state.list.page = -1;
      })
      .addCase(pageReports.fulfilled, (state, action) => {
        const {page, docs} = action.payload;

        const list = state.list;
        const forward = page > list.page;

        if (forward) {
          list.stack.push(list.first);
        }
        else {
          list.stack.pop();
        }

        list.page = page;
        list.values = (docs||[]).map(doc => Report.create(doc));
        list.last = last(docs);
        list.first = first(docs);
      })
  }
});

export const {
  clearReport, openReport, setHasSubject, setSubject,
  setNewTemplate, setTemplateTitle, applyTemplate, updateStyle, updateConfig, updateView, updateAllFonts,
  addColumn, removeColumn, moveColumn, setColumnName, addFieldsToColumn, removeFieldFromColumn, moveFieldInColumn,
  setFieldStyleInColumn, setFieldLabelInColumn,
  addSection, removeSection, moveSection, moveSectionToPage, setSectionType, setSectionTitle, toggleSectionBorder, toggleSectionSpan,
  addFieldToSection, removeFieldFromSection, moveFieldInSection, setFieldInSection, setFieldStyleInSection, setFieldLabelInSection, setFieldSpanInSection,
  addPage, removePage,
  setSectionCategory, setSectionOrientation, setSectionTags, setSectionHeight, setSectionAspect,
  addFieldsToMapPopup, moveFieldInMapPopup, removeFieldFromMapPopup,
  sortReports, initGenerateProgress, incGenerateProgress,
  patchTemplate
} = report.actions;

export default report.reducer;
