import React, {Fragment} from "react";
import {useSelector} from 'react-redux';
import { DndProvider } from 'react-dnd'
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {ThemeProvider, StyledEngineProvider, GlobalStyles} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from "@mui/material/CssBaseline";
import {LocalizationProvider} from '@mui/x-date-pickers';
import Backend from 'react-dnd-html5-backend'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AuthPage from '../auth/AuthPage';
import JoinPage from '../auth/JoinPage';
import PasswordPage from '../auth/PasswordPage';
import SetupPage from '../signup/SetupPage';
import SignupPage from '../signup/SignupPage';
import Spinner from '../util/Spinner';
import Main from './Main';


const theme = createTheme({
    palette: {
        primary: {
            main: "#26a69a",
            light: "#1de9b6",
            dark: "#00695c"
        },
        secondary: {
            main: "#ef5350",
            light: "#ff1744",
            dark: "#c62828"
        },
        contrast: {
            main: "#333"
        },
        background: {
            default: "#fafafa"
        },
        // secondary: {
        //     main: "#616161",
        //     light: "#8e8e8e",
        //     dark: "#373737"
        // }
    },
    typography: {
        h1: {
            fontSize:  "3em",
            fontWeight: 300,
            lineHeight: "1.15em"
        },
        h2: {
            fontSize:  "2.4em",
            fontWeight: 300,
            lineHeight: "1.5em"
        },
        h3: {
            fontSize: "1.6em",
            fontWeight: 300,
            lineHeight: "1.4em"
        },
        h4: {
            fontSize:  "1.15em",
            fontWeight: 300,
            lineHeight: "1.4em"
        },
        h5: {
            fontSize:  "1.25em",
            fontWeight: 300,
            lineHeight: "1.4em"
        },
        h6: {
            fontSize:  "1em",
            fontWeight: 500,
            textTransform: "uppercase"
        },
        subtitle1: {
            fontWeight: 300,
        },
        subtitle2: {
            fontWeight: 200,
        },
        caption: {
            color: "rgba(0,0,0,0.54)",
            lineHeight: 1.35
        },
        tableHeader: {
            fontSize: "0.75rem",
            fontWeight: 700,
            textTransform: "uppercase",
        },
        // subtitle2: {
        //     fontSize:
        // },
        // body1: {
        //     fontSize:
        // },
        // body2: {
        //     fontSize:
        // },
        // button: {
        //     fontSize:
        // },
        // caption: {
        //     fontSize:
        // },
        // overline: {
        //     fontSize:
        // }
    },
    shadows: [
        "none",
        "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
    ],
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            }
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: "2px",
            },
        },
    },
});

const CssOverride = <GlobalStyles styles={{
    '.firebase-emulator-warning': {
        top: 0,
        bottom: 'initial !important',
        opacity: 0.5
    },
    a: {
        textDecoration: "none",
        color: theme.palette.primary.dark,
        "&:hover": {
            textDecoration: "underline",
        }
    },
    '.MuiFormHelperText-root.MuiFormHelperText-contained': {
        marginLeft: theme.spacing(0.5)
    },

    '.MuiInputBase-input.MuiOutlinedInput-input, .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline, .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
        backgroundColor: 'white'
    },

    '.MuiTypography-root em': {
        fontStyle: "normal",
        fontWeight: "bold",
        color: theme.palette.primary.main,
    },

    '.MuiAlert-filled .MuiAlert-message em': {
        color: 'white'
    },

    '.MuiDialogTitle-root': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },

    '.MuiBadge-badge': {
        zIndex: '1051 !important',
    },

    '.MuiFormLabel-asterisk': {
        color: theme.palette.error.main,
        fontSize: '1.25em'
    }
}}/>
// const CssOverride = withStyles({
//     '@global': {
//         '.MuiOutlinedInput-root': {
//             backgroundColor: "white"
//         },
//         '.MuiFormHelperText-contained': {
//             marginLeft: theme.spacing(0.5)
//         },
//         'a': {
//             textDecoration: "none",
//             color: theme.palette.primary.dark,
//             "&:hover": {
//                 textDecoration: "underline",
//             }
//         },
//         '.MuiAlert-filledError': {
//             backgroundColor: theme.palette.secondary.main
//         }
//     },
// })(() => null);

const Scaffold = () => {
    const isInit = useSelector(state => state.auth && "user" in state.auth);
    const isLoggedIn = useSelector(state => state.auth && !!state.auth.user && !!state.auth.org);
    return (
      <Router>
          {
              isInit ? (
                isLoggedIn ?
                  <Main/>
                  :
                  <Fragment>
                      <Route exact path="/" component={AuthPage}/>
                      <Route exact path="/login" component={AuthPage}/>
                      <Route exact path="/password" component={PasswordPage}/>
                      <Route exact path="/join" component={JoinPage}/>
                      <Route exact path="/signup" component={SignupPage}/>
                      <Route exact path="/setup" component={SetupPage}/>
                  </Fragment>
              ) : <Spinner absolute={true}/>
          }
      </Router>
    )
};

const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <DndProvider backend={Backend}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box display="flex">
                            <CssBaseline/>
                            {CssOverride}
                            <Scaffold/>
                        </Box>
                    </LocalizationProvider>
                </DndProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
