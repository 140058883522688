import React from "react";
import {useDispatch} from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import {addColumn} from '../state';
import Column from "./Column";
import {FormSection} from "../../util/Form";
import Tip from "../../util/Tip";


const ColumnBuilder = ({config, configName, ...props}) => {
    const dispatch = useDispatch();
    return (
        <FormSection label="Columns" help="Configure the columns of the table. Fields and be added, removed, and re-arranged within columns. Columns
                can also be added/removed, and re-arranged."
                     action={
                         <Tooltip title="Add Column">
                             <Fab size="small" color="primary" onClick={() => dispatch(addColumn(configName))} sx={{marginRight: "-25px"}}>
                                 <AddIcon />
                             </Fab>
                         </Tooltip>}>

            <Tip id="labelEdit" mb={1}>
                You can rename fields by clicking on the field and editing to the desired value. After changing a field, the original will be shown below it for reference purposes. Make sure you hit the <em>Enter</em> key after making edits.
            </Tip>
            <Grid container spacing={1}>
                {(config.columns||[]).map((col, i) =>
                    <Grid item xs={12} md={6} key={col.id}>
                        <Column {...props} key={col.id} index={i} column={col} configName={configName}/>
                    </Grid>)}
            </Grid>
        </FormSection>
    )
};

export default ColumnBuilder;
